/* eslint-disable no-use-before-define */
import root from '../../router/root'
import agent from '../../router/agent'

import rootNav from '../../navigation/root'
import agentNav from '../../navigation/agent'

export const rootAbility = () => abilityLoop(root, rootNav)

export const agentAbility = () => abilityLoop(agent, agentNav)
export const initialAbility = [
]
const dontShowFirst = [

]

function abilityLoop(data, dataNav) {
  const resource = []
  const array = []
  const action = ['read', 'create', 'update', 'delete']
  const role = localStorage.getItem('role') ? localStorage.getItem('role') : ''
  const agentId = localStorage.getItem('agentId') ? localStorage.getItem('agentId') : null

  for (let index = 0; index < data.length; index += 1) {
    if (data[index].meta.resource !== 'Auth') {
      if (!resource.includes(data[index].meta.resource)) {
        resource.push(data[index].meta.resource)
        if (role === 'root' && !agentId) {
          // eslint-disable-next-line no-unused-vars
          action.forEach((item, indexAction) => {
            array.push({ subject: data[index].meta.resource, action: item })
          })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!dontShowFirst.includes(data[index].meta.resource)) {
            // eslint-disable-next-line no-unused-vars
            action.forEach((item, indexAction) => {
              array.push({ subject: data[index].meta.resource, action: item })
            })
          }
        }
      }
    }
  }
  for (let index = 0; index < dataNav.length; index += 1) {
    if (dataNav[index].resource !== 'Auth') {
      if (!resource.includes(dataNav[index].resource)) {
        if (Array.isArray(dataNav[index].children)) {
          for (let indexResource = 0; indexResource < dataNav[index].children.length; indexResource += 1) {
            if (!resource.includes(dataNav[index].children[indexResource].resource)) {
              resource.push(dataNav[index].children[indexResource].resource)
            }
          }
        }
        resource.push(dataNav[index].resource)
        if (role === 'root' && !agentId) {
          // eslint-disable-next-line no-unused-vars
          action.forEach((item, indexAction) => {
            array.push({ subject: dataNav[index].resource, action: item })
          })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!dontShowFirst.includes(dataNav[index].resource)) {
            // eslint-disable-next-line no-unused-vars
            action.forEach((item, indexAction) => {
              array.push({ subject: dataNav[index].resource, action: item })
            })
          }
        }
        if (Array.isArray(dataNav[index].children)) {
          for (let indexResource = 0; indexResource < dataNav[index].children.length; indexResource += 1) {
            if (role === 'root' && !agentId) {
              // eslint-disable-next-line no-unused-vars
              action.forEach((item, indexAction) => {
                array.push({ subject: dataNav[index].children[indexResource].resource, action: item })
              })
            } else {
              // eslint-disable-next-line no-lonely-if
              if (!dontShowFirst.includes(dataNav[index].children[indexResource].resource)) {
                // eslint-disable-next-line no-unused-vars
                action.forEach((item, indexAction) => {
                  array.push({ subject: dataNav[index].children[indexResource].resource, action: item })
                })
              }
            }
          }
        }
      }
    }
  }
  return array
}

export const _ = undefined
