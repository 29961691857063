export default [
  {
    route: 'agent-dashboard',
    title: 'agent-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Agent-dashboard',
    action: 'read',
  },
  {
    route: 'agent-monitor',
    title: 'Monitor',
    slug: 'monitor',
    icon: 'MonitorIcon',
    i18n: 'Monitor',
    resource: 'Agent-monitor',
    action: 'read',
  },
  {
    route: 'agent-staff',
    title: 'Staff',
    slug: 'staff',
    icon: 'UsersIcon',
    i18n: 'staff',
    resource: 'Agent-staff',
    action: 'read',
  },
  {
    route: 'agent-cars',
    title: 'Cars',
    slug: 'sars',
    icon: 'TruckIcon',
    i18n: 'Cars',
    resource: 'Agent-cars',
    action: 'read',
  },
  {
    route: 'agent-booking',
    title: 'Booking',
    slug: 'booking',
    icon: 'BookIcon',
    i18n: 'Booking',
    resource: 'Agent-booking',
    action: 'read',
  },
  {
    route: 'agent-report',
    title: 'Report',
    slug: 'report',
    icon: 'BarChartIcon',
    i18n: 'Report',
    resource: 'Agent-report',
    action: 'read',
  },
  {
    route: 'agent-setting',
    title: 'Setting',
    slug: 'setting',
    icon: 'SettingsIcon',
    i18n: 'Setting',
    resource: 'Agent-setting',
    action: 'read',
  },
  {
    route: 'agent-support',
    title: 'Support',
    slug: 'support',
    icon: 'LifeBuoyIcon',
    i18n: 'Support',
    resource: 'Agent-support',
    action: 'read',
  },
]
