<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <!-- <router-view /> -->
      <!-- <h1>ss</h1> -->
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'
import {
  // eslint-disable-next-line no-unused-vars
  initialAbility, rootAbility, agentAbility,
} from '@/libs/acl/config'

import store from '@/store'
// eslint-disable-next-line no-unused-vars
import axios from '@axios'

const LayoutVertical = () => import('@/layouts/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutVertical,
    LayoutFull,

    ScrollToTop,

  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    menuService() {
      return store.state.appConfig.menuService
    },
    wsClient() {
      return store.state.websockets.wsClient.WEB_SOCKET !== {} && store.state.websockets.wsClient.WEB_SOCKET !== null && store.state.websockets.wsClient.WEB_SOCKET !== undefined ? store.state.websockets.wsClient.WEB_SOCKET : null
    },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  watch: {
    menuService(val) {
      console.log('val', val)

      const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
      if (this.wsClient) {
        store.dispatch('websockets/disconnect', 'WEB_SOCKET')
      }
      if (val === 'root') {
        const pathname = `/Web?type=root&userId=${userData.userId}`

        const params = {
          pathname,
          page: 'WEB_SOCKET',
        }
        store.dispatch('websockets/connect', params)
      } else if (val === 'agent') {
        const pathname = `/Web?type=agent&userId=${userData.userId}`

        const params = {
          pathname,
          page: 'WEB_SOCKET',
        }
        store.dispatch('websockets/connect', params)
      }
    },
    $route(to) {
      document.title = `${this.$i18n.t(to.meta.pageTitle)} | ReferHub` || 'Your Website'
      const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
      if (to.meta.service && userData != null) {
        store.commit('appConfig/UPDATE_MENU_SERVICE', to.meta.service)
        if (to.meta.service === 'root') {
          if (userData.userType === 'root') {
            this.$ability.update(rootAbility())
            localStorage.setItem('ability', JSON.stringify(rootAbility()))
          }
          store.commit('appConfig/UPDATE_NAVBAR_TITLE', 'Developer')
          // eslint-disable-next-line global-require
          store.commit('appConfig/UPDATE_NAVBAR_IMAGE', require('@/assets/images/logo/logo.png'))
          localStorage.removeItem('agentId')
          localStorage.removeItem('agentData')
        } else if (to.meta.service === 'agent') {
          const agentData = localStorage.getItem('agentData') ? JSON.parse(localStorage.getItem('agentData')) : ''
          if (userData.userType === 'agent' || userData.userType === 'root') {
            if (userData.userType === 'agent') {
              this.$ability.update(agentAbility())
              localStorage.setItem('ability', JSON.stringify(agentAbility()))
            } else if (userData.userType === 'root') {
              this.$ability.update(agentAbility())
              localStorage.setItem('ability', JSON.stringify(agentAbility()))
            }
          }
          store.commit('appConfig/UPDATE_NAVBAR_TITLE', agentData.name)
          store.commit('appConfig/UPDATE_NAVBAR_IMAGE', agentData.image)
        }
      } else {
        store.commit('appConfig/UPDATE_NAVBAR_TITLE', 'ReferHub')
        this.$ability.update(initialAbility)
        localStorage.setItem('ability', JSON.stringify(initialAbility))
      }
    },
  },
  created() {
    const ability = localStorage.getItem('ability') ? JSON.parse(localStorage.getItem('ability')) : []
    this.$ability.update(ability)
    // axios.get('api/system/get')
    //   .then(response => {
    //     // console.log(response)
    //     store.commit('appConfig/UPDATE_SYSTEM_CONFIG', response.data.data)
    //   })
    //   // eslint-disable-next-line no-unused-vars
    //   .catch(error => {
    //     // console.log(error)
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'bottom-right',
    //       props: {
    //         title: this.$t('Error'),
    //         icon: 'ErrorIcon',
    //         variant: 'danger',
    //         text: this.$t(error.response.data.message),
    //       },
    //     })
    //   })
  },
  beforeUpdate() {
    // console.log('beforeUpdate', this.wsClient)
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)
        console.log('SocketMessage')
        console.log(SocketMessage)
        if (SocketMessage.message === 'WS_CONNECTED') {
          this.showToast(
            'success',
            'bottom-right',
            `User Id : ${SocketMessage.data.userId}`,
            'CheckIcon',
            this.$t(`${SocketMessage.message}`),
          )
        } else if (SocketMessage.message === 'WS_SEND_DATA') {
          console.log('WS_SEND_DATA')
          if (this.menuService === 'root') {
            this.rootReceiveMessage(SocketMessage)
          } else if (this.menuService === 'agent') {
            this.agentReceiveMessage(SocketMessage)
          }
        }
      }
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    rootReceiveMessage(SocketMessage) {
      if (SocketMessage.data.send.page === 'SYSTEM') {
        console.log('WS_SYSTEM')
        const STORE_MODULE_NAME = 'system'
        if (store.hasModule(STORE_MODULE_NAME)) {
          store.commit('appConfig/UPDATE_SYSTEM_CONFIG', SocketMessage.data.send.data)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'ErrorIcon',
              variant: 'success',
              text: this.$t('EDIT_SUCCESS'),
            },
          })
          store.commit(`${STORE_MODULE_NAME}/UPDATE`, SocketMessage.data.send.data)
        }
      }
    },
    agentReceiveMessage(SocketMessage) {
      if (SocketMessage.data.send.page === 'SYSTEM') {
        console.log('WS_SYSTEM')
        store.commit('appConfig/UPDATE_SYSTEM_CONFIG', SocketMessage.data.send.data)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Success'),
            icon: 'ErrorIcon',
            variant: 'success',
            text: this.$t('EDIT_SUCCESS'),
          },
        })
      } else if (SocketMessage.data.send.page === 'SERVICE_WEB_ABILITY') {
        this.$ability.update(SocketMessage.data.send.data)
        localStorage.setItem('ability', JSON.stringify(SocketMessage.data.send.data))
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Success'),
            icon: 'ErrorIcon',
            variant: 'success',
            text: this.$t('ABILITY_UPDATE'),
          },
        })
      }
    },

    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
