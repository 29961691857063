export default [
  {
    path: '/agent',
    meta: {
      pageTitle: 'Dashboard',
      service: 'agent',
      resource: 'Agent-dashboard',
      action: 'read',
    },
    redirect: { name: 'agent-dashboard' },
  },
  {
    path: '/agent/dashboard',
    name: 'agent-dashboard',
    component: () => import('../views/agent/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'agent',
      resource: 'Agent-dashboard',
      action: 'read',
    },
  },
  {
    path: '/agent/monitor',
    name: 'agent-monitor',
    component: () => import('../views/agent/monitor/Monitor.vue'),
    meta: {
      pageTitle: 'Monitor',
      service: 'agent',
      resource: 'Agent-monitor',
      action: 'read',
    },
  },
  {
    path: '/agent/staff',
    name: 'agent-staff',
    component: () => import('../views/agent/staff/Staff.vue'),
    meta: {
      pageTitle: 'Staff',
      service: 'agent',
      resource: 'Agent-staff',
      action: 'read',
    },
  },
  {
    path: '/agent/cars',
    name: 'agent-cars',
    component: () => import('../views/agent/cars/Cars.vue'),
    meta: {
      pageTitle: 'cars',
      service: 'agent',
      resource: 'Agent-cars',
      action: 'read',
    },
  },
  {
    path: '/agent/booking',
    name: 'agent-booking',
    component: () => import('../views/agent/booking/Booking.vue'),
    meta: {
      pageTitle: 'Booking',
      service: 'agent',
      resource: 'Agent-booking',
      action: 'read',
    },
  },
  {
    path: '/agent/report',
    name: 'agent-report',
    component: () => import('../views/agent/report/Report.vue'),
    meta: {
      pageTitle: 'Report',
      service: 'agent',
      resource: 'Agent-report',
      action: 'read',
    },
  },
  {
    path: '/agent/setting',
    name: 'agent-setting',
    component: () => import('../views/agent/setting/Setting.vue'),
    meta: {
      pageTitle: 'Setting',
      service: 'agent',
      resource: 'Agent-setting',
      action: 'read',
    },
  },
  {
    path: '/agent/support',
    name: 'agent-support',
    component: () => import('../views/agent/support/Support.vue'),
    meta: {
      pageTitle: 'support',
      service: 'agent',
      resource: 'Agent-support',
      action: 'read',
    },
  },
]
