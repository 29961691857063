export default [
  {
    path: '/root',
    meta: {
      service: 'root',
      resource: 'Root-dashboard',
      action: 'read',
    },
    redirect: { name: 'root-dashboard' },
  },
  {
    path: '/root/dashboard',
    name: 'root-dashboard',
    component: () => import('../views/root/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'root',
      resource: 'Root-dashboard',
      action: 'read',
    },
  },
  {
    path: '/root/agents',
    name: 'root-agents',
    component: () => import('../views/root/agent/agents/Agents.vue'),
    meta: {
      pageTitle: 'Agents',
      service: 'root',
      resource: 'Root-agents',
      action: 'read',
    },
  },
  {
    path: '/root/agent/package',
    name: 'root-agent-package',
    component: () => import('../views/root/agent/agentPackage/AgentPackage.vue'),
    meta: {
      pageTitle: 'Agent Package',
      service: 'root',
      resource: 'Root-agent-package',
      action: 'read',
    },
  },
  {
    path: '/root/hospital',
    name: 'root-hospital',
    component: () => import('../views/root/hospital/Hospital.vue'),
    meta: {
      pageTitle: 'Hospital',
      service: 'root',
      resource: 'Root-hospital',
      action: 'read',
    },
  },
  {
    path: '/root/member',
    name: 'root-member',
    component: () => import('../views/root/member/Member.vue'),
    meta: {
      pageTitle: 'Member',
      service: 'root',
      resource: 'Root-member',
      action: 'read',
    },
  },
  {
    path: '/root/car/accessories',
    name: 'root-car-accessories',
    component: () => import('../views/root/carSetting/carAccessories/CarAccessories.vue'),
    meta: {
      pageTitle: 'CarAccessories',
      service: 'root',
      resource: 'Root-car-accessories',
      action: 'read',
    },
  },
  {
    path: '/root/car/packages',
    name: 'root-car-packages',
    component: () => import('../views/root/carSetting/carPackages/CarsPackages.vue'),
    meta: {
      pageTitle: 'CarPackages',
      service: 'root',
      resource: 'Root-car-packages',
      action: 'read',
    },
  },
  {
    path: '/root/car/model',
    name: 'root-car-model',
    component: () => import('../views/root/carSetting/carModel/CarModel.vue'),
    meta: {
      pageTitle: 'CarModel',
      service: 'root',
      resource: 'Root-car-model',
      action: 'read',
    },
  },
  {
    path: '/root/car/model/view/:id',
    name: 'root-car-model-view',
    component: () => import('../views/root/carSetting/carModel/View.vue'),
    meta: {
      pageTitle: 'View Car Model',
      service: 'root',
      resource: 'Root-car-model-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Car Model',
          to: '/root/car/model',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'root-car-model',
    },
  },
  {
    path: '/root/formsurvey',
    name: 'root-form-survey',
    component: () => import('../views/root/formSurvey/FormSurvey.vue'),
    meta: {
      pageTitle: 'FormSurvey',
      service: 'root',
      resource: 'Root-form-survey',
      action: 'read',
    },
  },
  {
    path: '/root/formsurvey/add',
    name: 'root-formsurvey-add',
    component: () => import('../views/root/formSurvey/AddForm.vue'),
    meta: {
      pageTitle: 'FormSurvey-Add',
      parent: 'root-form-survey',
      service: 'root',
      navActiveLink: 'root-form-survey',
    },
  },
  {
    path: '/root/formsurvey/edit/:id',
    name: 'root-formsurvey-edit',
    component: () => import('../views/root/formSurvey/EditForm.vue'),
    meta: {
      pageTitle: 'FormSurvey-Edit',
      parent: 'root-form-survey',
      service: 'root',
      navActiveLink: 'root-form-survey',
    },
  },
  {
    path: '/root/formsurvey/addchild/:id/:index',
    name: 'root-formsurvey-addchild',
    component: () => import('../views/root/formSurvey/AddChildForm.vue'),
    meta: {
      pageTitle: 'FormSurvey-AddChild',
      parent: 'root-form-survey',
      service: 'root',
      navActiveLink: 'root-form-survey',
    },
  },
  {
    path: '/root/ticket',
    name: 'root-ticket',
    component: () => import('../views/root/support/ticket/Ticket.vue'),
    meta: {
      pageTitle: 'Ticket',
      service: 'root',
      resource: 'Root-ticket',
      action: 'read',
    },
  },
  {
    path: '/root/contactus',
    name: 'root-contactus',
    component: () => import('../views/root/support/contactUs/ContactUs.vue'),
    meta: {
      pageTitle: 'Contact Us',
      service: 'root',
      resource: 'Root-contactus',
      action: 'read',
    },
  },
  {
    path: '/root/contactus/chat/:id',
    name: 'root-contactus-chat',
    component: () => import('../views/root/support/contactUs/Chat.vue'),
    meta: {
      pageTitle: 'Chat',
      service: 'root',
      resource: 'Root-contactus-chat',
      action: 'read',
      breadcrumb: [
        {
          text: 'Contact Us',
          to: '/root/contactus',
        },
        {
          text: 'Chat',
          active: true,
        },
      ],
      navActiveLink: 'root-contactus',
    },
  },
  {
    path: '/root/privileges',
    name: 'root-privileges',
    component: () => import('../views/root/privileges/Privileges.vue'),
    meta: {
      pageTitle: 'Privileges',
      service: 'root',
      resource: 'Root-privileges',
      action: 'read',
    },
  },
  {
    path: '/root/news',
    name: 'root-news',
    component: () => import('../views/root/news/News.vue'),
    meta: {
      pageTitle: 'News',
      service: 'root',
      resource: 'Root-news',
      action: 'read',
    },
  },
  {
    path: '/root/apiMap',
    name: 'root-apiMap',
    component: () => import('../views/root/apiMap/ApiMap.vue'),
    meta: {
      pageTitle: 'ApiMap',
      service: 'root',
      resource: 'Root-apiMap',
      action: 'read',
    },
  },
  {
    path: '/root/wallet',
    name: 'root-wallet',
    component: () => import('../views/root/wallet/Wallet.vue'),
    meta: {
      pageTitle: 'Wallet',
      service: 'root',
      resource: 'Root-wallet',
      action: 'read',
    },
  },
  {
    path: '/root/settings',
    name: 'root-settings',
    component: () => import('../views/root/setting/Setting.vue'),
    meta: {
      pageTitle: 'Settings',
      service: 'root',
      resource: 'Root-settings',
      action: 'read',
    },
  },
  {
    path: '/root/logs/booking',
    name: 'root-logs-booking',
    component: () => import('../views/root/logs/booking/Booking.vue'),
    meta: {
      pageTitle: 'Booking History',
      service: 'root',
      resource: 'Root-logs-booking',
      action: 'read',
    },
  },
  {
    path: '/root/logs/trasaction',
    name: 'root-logs-transaction',
    component: () => import('../views/root/logs/transaction/transaction.vue'),
    meta: {
      pageTitle: 'Transaction History',
      service: 'root',
      resource: 'Root-logs-transaction',
      action: 'read',
    },
  },
  {
    path: '/root/logs/user',
    name: 'root-logs-user',
    component: () => import('../views/root/logs/user/user.vue'),
    meta: {
      pageTitle: 'User Logs',
      service: 'root',
      resource: 'Root-logs-user',
      action: 'read',
    },
  },
]
