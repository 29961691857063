export default [
  {
    route: 'root-dashboard',
    title: 'root-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Root-dashboard',
    action: 'read',
  },
  {
    route: null,
    title: 'Agent',
    slug: 'agent',
    icon: 'BriefcaseIcon',
    resource: 'Root-agent',
    i18n: 'Agent',
    children: [
      {
        route: 'root-agents',
        title: 'root-agents',
        slug: 'agents',
        icon: 'BriefcaseIcon',
        i18n: 'Agents',
        resource: 'Root-agents',
        action: 'read',
      },
      {
        route: 'root-agent-package',
        title: 'Agent Package',
        slug: 'agentpackage',
        icon: 'PackageIcon',
        i18n: 'Agent Package',
        resource: 'Root-agent-package',
        action: 'read',
      },
    ],
  },
  {
    route: 'root-hospital',
    title: 'Hospital',
    slug: 'hospital',
    icon: 'PlusIcon',
    i18n: 'Hospital',
    resource: 'Root-hospital',
    action: 'read',
  },

  {
    route: 'root-member',
    title: 'Member',
    slug: 'member',
    icon: 'UsersIcon',
    i18n: 'Member',
    resource: 'Root-member',
    action: 'read',
  },

  {
    route: null,
    title: 'Cars Setting',
    slug: 'carsSetting',
    icon: 'TruckIcon',
    resource: 'Root-cars-setting',
    i18n: 'CarsSetting',
    children: [
      {
        route: 'root-car-accessories',
        title: 'Car Accessories',
        slug: 'carAccessories',
        icon: 'EditIcon',
        i18n: 'CarAccessories',
        resource: 'Root-car-accessories',
        action: 'read',
      },
      {
        route: 'root-car-packages',
        title: 'Car Packages',
        slug: 'carPackages',
        icon: 'EditIcon',
        i18n: 'CarPackages',
        resource: 'Root-car-packages',
        action: 'read',
      },
      {
        route: 'root-car-model',
        title: 'Car Model',
        slug: 'carModel',
        icon: 'TruckIcon',
        i18n: 'CarModel',
        resource: 'Root-car-model',
        action: 'read',
      },
    ],
  },

  {
    route: 'root-form-survey',
    title: 'FormSurvey',
    slug: 'formSurvey',
    icon: 'SearchIcon',
    i18n: 'FormSurvey',
    resource: 'Root-form-survey',
    action: 'read',
  },

  {
    route: null,
    title: 'Support',
    slug: 'support',
    icon: 'LifeBuoyIcon',
    resource: 'Root-support',
    i18n: 'Support',
    children: [
      {
        route: 'root-ticket',
        title: 'Ticket',
        slug: 'ticket',
        icon: 'EditIcon',
        i18n: 'Ticket',
        resource: 'Root-ticket',
        action: 'read',
      },
      {
        route: 'root-contactus',
        title: 'Contact Us',
        slug: 'contactus',
        icon: 'MailIcon',
        i18n: 'Contact Us',
        resource: 'Root-contactus',
        action: 'read',
      },
    ],
  },

  {
    route: 'root-privileges',
    title: 'Privileges',
    slug: 'privileges',
    icon: 'PackageIcon',
    i18n: 'Privileges',
    resource: 'Root-privileges',
    action: 'read',
  },

  {
    route: 'root-news',
    title: 'News',
    slug: 'news',
    icon: 'PackageIcon',
    i18n: 'News',
    resource: 'Root-news',
    action: 'read',
  },

  {
    route: 'root-apiMap',
    title: 'root-apiMap',
    slug: 'apiMap',
    icon: 'HomeIcon',
    i18n: 'ApiMap',
    resource: 'Root-apiMap',
    action: 'read',
  },
  {
    route: 'root-wallet',
    title: 'Wallet',
    slug: 'wallet',
    icon: 'DollarSignIcon',
    i18n: 'Wallet',
    resource: 'Root-wallet',
    action: 'read',
  },
  {
    route: 'root-settings',
    title: 'Settings',
    slug: 'settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
    resource: 'Root-settings',
    action: 'read',
  },
  {
    route: null,
    title: 'Logs',
    slug: 'Logs',
    icon: 'BarChart2Icon',
    resource: 'Root-cars-setting',
    i18n: 'Logs',
    children: [
      {
        route: 'root-logs-booking',
        title: 'Booking History',
        slug: 'bookingLogs',
        icon: 'EditIcon',
        i18n: 'Booking History',
        resource: 'Root-logs-booking',
        action: 'read',
      },
      {
        route: 'root-logs-transaction',
        title: 'Transaction History',
        slug: 'transactionHistory',
        icon: 'EditIcon',
        i18n: 'Transaction History',
        resource: 'Root-logs-transaction',
        action: 'read',
      },
      {
        route: 'root-logs-user',
        title: 'User logs',
        slug: 'userLogs',
        icon: 'EditIcon',
        i18n: 'User Logs',
        resource: 'Root-logs-user',
        action: 'read',
      },
    ],
  },
]
