// eslint-disable-next-line no-unused-vars
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    notifications: [
      // {
      //   _id: 1,
      //   title: 'Congratulation Sam 🎉',
      //   // eslint-disable-next-line global-require
      //   avatar: require('@/assets/images/avatars/6-small.png'),
      //   subtitle: 'Won the monthly best seller badge',
      //   type: 'light-success',
      // },
      // {
      //   _id: 2,

      //   title: 'New message received',
      //   // eslint-disable-next-line global-require
      //   avatar: require('@/assets/images/avatars/9-small.png'),
      //   subtitle: 'You have 10 unread messages',
      //   type: 'light-info',
      // },
      // {
      //   _id: 3,

      //   title: 'Revised Order 👋',
      //   avatar: 'MD',
      //   subtitle: 'MD Inc. order updated',
      //   type: 'light-danger',
      // },
    ],
    systemNotifications: [
      {
        _id: 1,

        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        _id: 2,

        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        _id: 3,

        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ],
  },
  getters: {},
  mutations: {
    ADD_NOTIFICATION(state, val) {
      state.notifications.push(val)
    },
    REMOVE_NOTIFICATION(state, id) {
      if (state.notifications != null) {
        const ItemIndex = state.notifications.findIndex(p => p._id === id)
        state.notifications.splice(ItemIndex, 1)
      }
    },
    UNSHIFT_NOTIFICATIONS(state) {
      state.notifications.unshift({
        _id: state.notifications.length + 1,
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      })
    },
    UPDATE_NOTIFICATIONS(state, val) {
      state.notifications = val
    },
    UPDATE_SYSTEM_NOTIFICATIONS(state, val) {
      state.systemNotifications = val
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getFromApplication({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/utils/notification/get')
          .then(response => {
            commit('UPDATE_NOTIFICATIONS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    readFromApplication({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/utils/notification/read?id=${item}`)
          .then(response => {
            commit('REMOVE_NOTIFICATION', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
