import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDateTime', value => moment(String(value)).format('HH:mm:ss DD-MM-YYYY'))
Vue.filter('formatDate', value => moment(String(value)).format('DD-MM-YYYY'))

Vue.filter('firstStringUpper', value => {
  const text = value.substr(0, 1)
  const word = value.slice(1)
  return Vue.prototype.$stateTranslate.t(text.toUpperCase() + word) // Vue.prototype.$stateTranslate มาจากไฟล์ main.js
})
